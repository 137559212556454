<template>
  <div>
    <b-col
      cols="12"
      class="mt-2 tituloNome"
    >
      Olá, {{ collaboratorName }}
    </b-col>
    <b-col
      cols="12"
      class="subtituloNome"
    >
      Por favor, selecione a empresa desejada:
    </b-col>
    <b-col
      cols="12"
      class="mb-1 mt-2"
    >
      <v-select
        id="select-company"
        class="custom-select-company-login"
        v-model="selectedCompany"
        :clearable="false"
        variant="custom"
        label="nome_fantasia"
        :options="companies"
        placeholder="Selecione a empresa"
        @input="selectCompany"
      />
    </b-col>
    <b-col
      cols="12"
    >
      <button
        :disabled="disabledButton"
        type="submit"
        class="btn button-form btn-block"
        @click.prevent="sendToLogin()"
      >
        {{ msgButton }}
      </button>
    </b-col>
    <b-col
      cols="12"
      class="mt-1 text-center"
    >
      <b-link
        :disabled="disabledReturn"
        @click.prevent="retornaLoginEmpresa()"
      >
        <BIconChevronLeft/>
        <span class="ml-1">Voltar ao login</span>
      </b-link>
    </b-col>
  </div>
</template>

<script>
import {
  BCol,
  BLink,
  BIconChevronLeft
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BCol,
    BLink,
    BIconChevronLeft,
    vSelect
  },

  props: {
    choosingCompanyUser: {
      type: Object,
      default: false
    }
  },

  data() {
    return {
      collaboratorName: this.$props.choosingCompanyUser.authData.nome_colaborador,
      companies: this.$props.choosingCompanyUser.authData.empresas,
      authData: {
        access_token: this.$props.choosingCompanyUser.authData.access_token,
        id_colaborador: null
      },
      selectedCompany: null,
      msgButton: 'Entrar',
      disabledButton: true,
      disabledReturn: false
    }
  },

  methods: {
    retornaLoginEmpresa() {
      this.$emit('retornaLogin')
    },

    async sendToLogin() {
      const auth = {
        userData: this.authData,
        admin: false
      }
      this.disabledReturn = true
      this.buttonLoading(true)
      this.$emit('tokenLoginRecovery', auth)
    },

    selectCompany(selectedCompany){
      this.disabledButton = false
      this.authData.id_colaborador = selectedCompany.id_colaborador
    },

    buttonLoading() {
      this.msgButton = 'Processando...';
      this.disabledButton = true
    }
  }
}
</script>
<style scoped>
.tituloNome {
  font-size: 24px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  color: #5E5873;
}
.subtituloNome {
  font-size: 14px;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
  color: #5E5873;
}
</style>
<style lang="scss">
  .custom-select-company-login
    ::placeholder {
      color: #B9B9C3 !important;
    }
</style>
