<template>
  <Auth>
    <div
      class="card-auth"
    >
      <div
        v-if="choosingCompany"
        class="align-self-start d-flex"
      >
        <div
          class="barra-retangular"
          @click="retornaLogin()"
        ></div>
        <div
          class="
            barra-retangular
            barra-retangular-ativa
          "
        ></div>
      </div>
      <div
        class="m-auto"
      >
        <div
          class="logo-sesi"
        >
          <ApplicationLogo />
        </div>
        <b-alert
          v-if="alert.show"
          show
          fade
          class="mb-2"
          variant="danger"
        >
          <div class="custom-alert">
            <span>
              <BIconExclamationCircle />
              {{ alert.message }}
            </span>
          </div>
        </b-alert>
        <choose-company
          v-if="choosingCompany"
          :choosingCompanyUser="choosingCompanyUser"
          @errorHandling="errorHandling"
          @retornaLogin="retornaLogin"
          @tokenLoginRecovery="tokenLoginRecovery"
        />
        <login
          v-else
          @tokenLoginRecovery="tokenLoginRecovery"
          @chooseCompany="chooseCompany"
          @errorHandling="errorHandling"
          @changeAlertState="changeAlertState"
        />
      </div>
    </div>
  </Auth>
</template>

<script>
import Auth from "@/views/pages/authentication/BaseAuth/Auth.vue";
import Login from "@/views/pages/authentication/Login.vue";
import ChooseCompany from "@/views/pages/authentication/ChooseCompany.vue";
import ApplicationLogo from "@/views/components/custom/logo-sesi/ApplicationLogo";
import {
  BAlert,
  BIconExclamationCircle
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    Auth,
    Login,
    ApplicationLogo,
    ChooseCompany,
    BAlert,
    BIconExclamationCircle
  },

  data() {
    return {
      choosingCompany: false,
      choosingCompanyUser: null,
      alert: {
        message: '',
        show: false
      },
      disabledReturn: false
    }
  },

  methods: {
    async tokenLoginRecovery(userCredentials) {
      this.disabledReturn = true
      await this.$store.dispatch('sessions/login', userCredentials)
        .then((response) => {
          const status = response.request.status;
          const token = response.data.auth.access_token;
          const userData = response.data;

          if(status === 200 && token) {
            this.abilitySessionAndRedirect(userData);
          }
        })
        .catch(error => {
          this.errorHandling(error.response);
        })
    },

    abilitySessionAndRedirect(userData) {
      if(! userData.ability || userData.ability.length === 0){
        this.$store.dispatch('sessions/logout').then();
        this.$router.replace({ name: 'misc-not-enabled' });

        return false;
      }

      if(userData.status) {
        userData.status = 'active';
      }

      this.$ability.update(userData.ability);

      this.$router.replace({ name: 'bem-vindo'})
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Bem-vindo(a) ${userData.fullName}`,
              icon: 'CoffeeIcon',
              variant: 'success',
              text: '',
            },
          })
        })
    },

    chooseCompany(data){
      this.choosingCompany = true
      this.choosingCompanyUser = data
    },

    errorHandling(response) {
      let message = response.data.error;

      if(response.status !== 401) {
        message = 'Erro de conexão com o servidor, tente novamente mais tarde.';
      }

      this.changeAlertState(
        true,
        message
      );
    },

    retornaLogin() {
      if(!this.disabledReturn){
        this.choosingCompany = false
      }
    },

    changeAlertState(showState, message) {
      this.alert.show = showState
      this.alert.message = message
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
<style scoped>
.barra-retangular{
  width: 40px;
  height: 6px;
  background-color: #EFEFEF;
  border-radius: 12%;
  cursor: pointer;
}
.barra-retangular-ativa{
  margin-left: 8px;
  background-color: #D2D1D9;
  cursor: initial;
}
</style>
