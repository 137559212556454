<template>
  <div>
    <b-card-title
        class="mb-1 font-weight-bold"
        title-tag="h2"
    >
      Olá!
    </b-card-title>
    <b-card-text class="mb-2">
      Por favor, insira os dados da sua conta para acessar nosso portal.
    </b-card-text>

    <b-form
        class="mt-2"
        @submit.prevent="login"
    >
      <!-- email -->
      <b-form-group
          label="E-mail"
          label-for="login-email"
      >

        <b-form-input
            id="login-email"
            v-model="auth.email"
            @focus="() => this.$emit('changeAlertState', false)"
            name="login-email"
            placeholder="email@email.com"
            autocomplete="off"
        />
        <small class="text-danger">{{ formErrors.email }}</small>
      </b-form-group>

      <!-- forgot password -->
      <b-form-group>
        <div class="d-flex justify-content-between">
          <label for="login-password">Senha</label>
          <b-link :to="{ name: `auth-forgot-password` }">
            <small>Esqueci minha senha</small>
          </b-link>
        </div>

        <b-input-group
            class="input-group-merge"
        >
          <b-form-input
              id="login-password"
              v-model="auth.password"
              @focus="() => this.$emit('changeAlertState', false)"
              class="form-control-merge"
              :type="passwordFieldType"
              name="login-password"
              placeholder="******"
              autocomplete="off"
          />
          <b-input-group-append is-text>
            <feather-icon
                class="cursor-pointer"
                :icon="passwordToggleIcon"
                @click="togglePasswordVisibility"
            />
          </b-input-group-append>
        </b-input-group>
        <small class="text-danger">{{ formErrors.password }}</small>

      </b-form-group>

      <button :disabled="disabledButton" type="submit" class="btn button-form btn-block mb-2">
        {{ msgButton }}
      </button>
    </b-form>

    <b-card-text class="text-center margem-div">
      <span>Ainda não é Cliente SESI? </span>
      <br>
      <a href="https://sesisc.org.br/pt-br#block-sesidestaques" target="_blank">
        <span>&nbsp;Descubra nossos produtos!</span>
      </a>
      <hr />
      <b-link :to="{name:'auth-login-admin'}">
        Área administrativa
      </b-link>
    </b-card-text>
  </div>
</template>

<script>
import {
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BForm,
  BButton
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { validation } from '@core/mixins/validation/validation'
import { messages } from "@core/mixins/validation/messages";

export default {
  components: {
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BForm,
    BButton
  },
  mixins: [togglePasswordVisibility, validation, messages],
  data() {
    return {
      auth: {
        email: '',
        password: ''
      },
      loadingShow: true,
      disabledButton: false,
      msgButton: 'Entrar',
      formErrors: {}
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    async login() {
      if(this.validation()) {
        this.buttonLoading(true);
        await this.$http
        .post(
          this.$api.LoginAuthVerify,
          this.auth
        )
        .then((response) => {
          if(response.data.empresas.length === 1) {
              const auth = {
                userData: {
                  access_token: response.data.access_token,
                  id_colaborador: response.data.empresas[0].id_colaborador
                },
                admin: false
              }
            this.$emit('tokenLoginRecovery', auth)
          }else{
            this.buttonLoading(false);
            this.$emit('chooseCompany',
              {
                authData: response.data
              }
            )
          }
        })
        .catch(error => {
          this.buttonLoading(false);
          this.$emit('errorHandling', error.response)
        })
      }
    },

    validation() {
      this.emailValidate(this.auth.email);

      this.strValidate({
        input: this.auth.password,
        message: this.passwordMsg,
        field: 'password'
      });

      if(this.formValidationRun()) {
        return true;
      } else {
        this.formErrors = {};
        this.formErrors = this.formValidationErrors;

        return false;
      }
    },

    buttonLoading(disabled) {
      if(disabled) {
        this.disabledButton = true;
        this.msgButton = 'Processando...';
      } else {
        this.disabledButton = false;
        this.msgButton = 'Entrar';
      }
    },
  },
}
</script>
